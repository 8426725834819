import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_KPMGCaseStudy = _resolveComponent("KPMGCaseStudy")
  const _component_HustleFundCaseStudy = _resolveComponent("HustleFundCaseStudy")
  const _component_SFF2021CaseStudy = _resolveComponent("SFF2021CaseStudy")
  const _component_SWITCH2021CaseStudy = _resolveComponent("SWITCH2021CaseStudy")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_NavBar, { "logged-in": _ctx.isLoggedIn }, null, 8, ["logged-in"]),
    (this.$route.params.caseStudy == 'kpmg')
      ? (_openBlock(), _createBlock(_component_KPMGCaseStudy, { key: 0 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'hustle-fund')
      ? (_openBlock(), _createBlock(_component_HustleFundCaseStudy, { key: 1 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'sff-2021')
      ? (_openBlock(), _createBlock(_component_SFF2021CaseStudy, { key: 2 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'switch-2021')
      ? (_openBlock(), _createBlock(_component_SWITCH2021CaseStudy, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}