
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import KPMGCaseStudy from "@/components/public/case-studies/KPMGCaseStudy.vue";
import SFF2021CaseStudy from "@/components/public/case-studies/SFF2021CaseStudy.vue";
import SWITCH2021CaseStudy from "@/components/public/case-studies/SWITCH2021CaseStudy.vue";
import HustleFundCaseStudy from "@/components/public/case-studies/HustleFundCaseStudy.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
@Options({
  components: {
    NavBar,
    KPMGCaseStudy,
    HustleFundCaseStudy,
    SFF2021CaseStudy,
    SWITCH2021CaseStudy
  }
})
export default class CaseStudyBase extends Vue {
  isLoggedIn = false;
  loaded = false;
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
