
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
interface Example {
  image: string;
  title: string;
  subtitle: string;
  slug?: string;
}
@Options({
  inject: ["mq"],
  computed: {
    mobile() {
      return this.mq.current === "sm" || this.mq.current === "md"
        ? "uk-width-2-3"
        : "uk-width-1-3";
    }
  }
})
export default class Examples extends Vue {
  BASE_CASE_STUDIES = "/case-studies";
  examples: Example[] = [
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-1.png",
      title: "HUSTLE FUND",
      subtitle:
        "Visualizing Hustle Fund's 1st AMA about the Hustleverse community and Web3",
      slug: this.BASE_CASE_STUDIES + "/hustle-fund"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-2.png",
      title: "KPMG",
      subtitle: "Visualizing a LinkedIn article on ASPAC's public sector",
      slug: this.BASE_CASE_STUDIES + "/kpmg"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-3.png",
      title: "SWITCH 2021",
      subtitle:
        "A contactless interactive wall and AI-generated visual summaries for an innovative tech festival",
      slug: this.BASE_CASE_STUDIES + "/switch-2021"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-4.png",
      title: "SINGAPORE FINTECH FESTIVAL 2021",
      subtitle:
        "Creating visual summaries at speed & scale for fireside chats, presentations, and panel discussions",
      slug: this.BASE_CASE_STUDIES + "/sff-2021"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-5.png",
      title: "SEQUOIA",
      subtitle: "Illustrated insights summarizing a 25-min podcast"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-6.png",
      title: "SEQUOIA (SURGE)",
      subtitle: "Visualizing a quick video to building startup culture"
    }
  ];
}
