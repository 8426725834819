
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class SWITCH2021CaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    finalImage:
      STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-3.png",
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    wallCase1:
      STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-Wall-1.png",
    wallCase2:
      STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-Wall-2.png",
    wallCase3:
      STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-Wall-3.png",
    wallCase4:
      STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-Wall-4.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-2.png",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-3.png",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SWITCH-5.png"
  };
  links: { [id: string]: string } = {
    enterpriseSG: "https://www.enterprisesg.gov.sg/",
    demo: "https://thoth.art/demo",
    ideaInk: "http://www.ideaink.co",
    switch: "https://www.switchsg.org/",
    services: "https://www.ideaink.co/art-tech"
  };
  goToServices() {
    window.location.href = this.links.services;
  }
  goToArticle() {
    window.location.href = this.links.demo;
  }
}
