
import { Vue, Options } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";

@Options({
  components: {
    ThothButton
  }
})
export default class BetaCallToAction extends Vue {
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  ButtonColour = ButtonColour;

  images: { [id: string]: string } = {
    ctaIllustrationOne: STATIC_ASSET_IMAGES_BASE_URL + "/cta-illo-1.png",
    ctaIllustrationTwo: STATIC_ASSET_IMAGES_BASE_URL + "/cta-illo-2.png"
  };

  goToWaitlist() {
    this.$router.push("/waitlist");
  }

  goToContact() {
    // Prepare the email details
    const subject = encodeURIComponent("Thoth Demo Request");
    const body = encodeURIComponent("");

    // Open the email app with pre-filled text
    window.location.href = `mailto:hello@thoth.art?subject=${subject}&body=${body}`;
  }
}
