<template>
  <div class="template-hero-section">
    <h5 class="heading-17">24 FINTECH</h5>
    <h1 class="heading-17" style="padding-bottom:15px;position:relative;z-index:1;">
      <span>Beyond</span> <span style="color:#19C8E1;">Borders,</span>
      <span>Beyond</span> <span style="color:#95733B;">Time</span>
    </h1>
    <p class="heading-17">Visual Summaries by Thoth.ai</p>
    <div class="old-container search-container">
      <form action="/24fintech2024/search" class="relative w-form">
        <img src="https://uploads-ssl.webflow.com/617a65c0040dea3ca7ce8b56/617a65c0040dea47fdce8b8a_search.svg" alt=""
          class="search-icon-2" /><input class="search-bar-2 w-input" autofocus="true" maxlength="256" name="query"
          placeholder="Search talk name, theme, speaker, etc." type="search" id="search" required="" /><input
          type="submit" class="search-submit-2 w-button" value="Search" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroSection"
};
</script>
