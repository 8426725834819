import { authenticate, authenticateAdmin, LOCAL_ACCESS_KEY } from "@/services";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "../store/index.js";
import { RESET_PROJECT_STORE_ACTION } from "../store/constants.js";

// Admin
import Admin from "@/views/admin/Admin.vue";
import Signup from "@/views/admin/Signup.vue";
import PasswordReset from "@/views/admin/PasswordReset.vue";
import Login from "../views/admin/Login.vue";

// Public-facing pages
import Home from "../views/public/Home.vue";
import Demo from "../views/public/Demo.vue";
import CaseStudyBase from "../views/public/CaseStudyBase.vue";
import About from "../views/public/About.vue";
import Pricing from "../views/public/Pricing.vue";
import Waitlist from "../views/public/contact/Waitlist.vue";
import PrivacyPolicy from "../views/public/legal/PrivacyPolicy.vue";
import License from "../views/public/legal/License.vue";
import TermsOfService from "../views/public/legal/TermsOfService.vue";
import Success from "../views/Success.vue";
import Deleted from "../views/Deleted.vue";
import Health from "../views/public/Health.vue";
//Product-Settings
import AccountProfile from "../components/product/settings/AccountProfile.vue";
import AccountDetails from "../components/product/settings/AccountDetails.vue";
import AccountNotifications from "../components/product/settings/AccountNotifications.vue";
import AccountPayments from "../components/product/settings/AccountPayments.vue";

// Thoth App
import Dashboard from "../views/product/Dashboard.vue";
import BrandKit from "../views/product/BrandKit.vue";
import Create from "../views/product/Create.vue";
import Preview from "../views/product/Preview.vue";
import Edit from "../views/product/Edit.vue";
import Publish from "../views/product/Publish.vue";
import Settings from "../views/product/Settings.vue";
import ViewInfographic from "../components/product/ViewInfographic.vue";

//Error pages
import ResourceNotFound from "../views/error/ResourceNotFound.vue";
import InternalServerError from "../views/error/InternalServerError.vue";

//WebLibrary
import WebLibrary from "../views/WebLibrary/Home.vue";
import StagePage from "../views/WebLibrary/StagePage.vue";
import TalkPage from "../views/WebLibrary/TalkPage.vue";
import DayPage from "../views/WebLibrary/DayPage.vue";
import SearchResultsPage from "../views/WebLibrary/SearchResultsPage.vue";
import AgendaPage from "../views/WebLibrary/AgendaPage.vue";
import AboutPage from "../views/WebLibrary/AboutPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/case-studies/:caseStudy",
    name: "CaseStudy",
    component: CaseStudyBase,
  },
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
  },
  {
    path: "/waitlist",
    name: "Waitlist",
    component: Waitlist,
    beforeEnter() {
      window.location.href = "https://form.typeform.com/to/Tbyclmz4";
    },
  },
  {
    path: "/24fintech2024",
    name: "WebLibraryHome",
    component: WebLibrary,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/stage/:name",
    name: "StagePage",
    component: StagePage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/talk/:name",
    name: "TalkPage",
    component: TalkPage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/day/:day",
    name: "DayPage",
    component: DayPage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/search",
    name: "SearchResultsPage",
    component: SearchResultsPage,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/agenda",
    name: "AgendaPage",
    component: AgendaPage,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024/about",
    name: "AboutPage",
    component: AboutPage,
    meta: {
      title: "Thoth.ai | 24 FinTech",
    },
  },
  {
    path: "/24fintech2024",
    name: "WebLibraryHome",
    component: WebLibrary,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/stage/:name",
    name: "StagePage",
    component: StagePage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/talk/:name",
    name: "TalkPage",
    component: TalkPage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/day/:day",
    name: "DayPage",
    component: DayPage,
    props: true,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/search",
    name: "SearchResultsPage",
    component: SearchResultsPage,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/agenda",
    name: "AgendaPage",
    component: AgendaPage,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/24fintech2024/about",
    name: "AboutPage",
    component: AboutPage,
    meta: {
      title: "Thoth.ai | 24 FinTech"
    }
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/license",
    name: "License",
    component: License,
  },
  {
    path: "/tos",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/careers",
    name: "Careers",
    redirect: "",
    beforeEnter() {
      window.location.href =
        "https://thoth-ai.notion.site/Careers-at-Thoth-166c5280c1d74870ad593920dd994a5c";
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/brandkit",
    name: "BrandKit",
    component: BrandKit,
  },
  {
    path: "/create",
    name: "Create",
    component: Create,
  },
  {
    path: "/project/:projectId/preview",
    name: "Preview",
    component: Preview,
  },
  {
    path: "/project/:projectId/edit",
    name: "Edit",
    component: Edit,
  },
  {
    path: "/project/:projectId/publish",
    name: "Publish",
    component: Publish,
  },
  {
    path: "/orders/payments",
    name: "Success",
    component: Success,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    children: [
      { path: "profile", component: AccountProfile },
      { path: "account", component: AccountDetails },
      { path: "notifications", component: AccountNotifications },
      { path: "payments", component: AccountPayments },
    ],
  },
  {
    path: "/404",
    name: "ResourceNotFound",
    component: ResourceNotFound,
  },
  {
    path: "/500",
    name: "InternalServerError",
    component: InternalServerError,
  },
  {
    path: "/deleted",
    name: "Deleted",
    component: Deleted,
  },
  {
    path: "/infographic/:projectId",
    name: "ViewInfographic",
    component: ViewInfographic,
  },
  {
    path: "/health",
    name: "Health",
    component: Health,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView();
  },
});

const PRODUCT_FLOW_ROUTES = ["Create", "Preview", "Edit", "Publish"];
const PROTECTED_ROUTES = [
  ...PRODUCT_FLOW_ROUTES,
  "Dashboard",
  "BrandKit",
  "Settings",
];

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem(LOCAL_ACCESS_KEY);
  const isAuthenticated = token ? await authenticate() : false;

  if (to.name === "Admin") {
    const isAdmin = token ? await authenticateAdmin() : false;
    if (isAdmin && isAuthenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    if (isAuthenticated || from.name == "Login") {
      next();
    } else if (PROTECTED_ROUTES.includes(String(to.name)) && !isAuthenticated) {
      next({ name: "Login" });
    } else if (to.name === "Success") {
      next({ name: "Pricing" });
    } else {
      next();
    }
  }
});
router.afterEach(async (to, from) => {
  if (
    PRODUCT_FLOW_ROUTES.includes(String(from.name)) &&
    to.name === "Dashboard"
  ) {
    store.dispatch(RESET_PROJECT_STORE_ACTION);
  }
});

export default router;
