<template>
  <div class="section hero">
    <!-- Stages Collection Section -->
    <div class="_2022-stages-collection">
      <div class="div-block-47">
        <div class="text-block-28">Click on a stage to start exploring:</div>
        <div class="collection-list-wrapper-4">
          <div
            v-if="stages.length > 0"
            role="list"
            class="category-tags-collection-list-2 category-front-page align_center"
          >
            <div
              v-for="stage in stages"
              :key="stage.id"
              role="listitem"
              class="collection-item-6"
            >
              <router-link
                :to="stage.link"
                class="category-front-2 with-margin blue w-inline-block"
              >
                <div class="cat-front-text-2">{{ stage.name }}</div>
              </router-link>
            </div>
          </div>
          <div v-else class="w-dyn-empty">
            <div>No items found.</div>
          </div>
        </div>
        <router-link :to="`/24fintech2024/agenda`" class="link-9"
          >View All stages</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HeroSection",
  data() {
    return {
      stages: [], // Store the stages
      data: null // Store the fetched data here
    };
  },
  async mounted() {
    await this.fetchData(); // Ensure data is fetched before loading stages
    this.loadStages();
  },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        // Once data is fetched, load the talk data
        this.loadTalkData(this.$route.params.name);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    loadStages() {
      if (!this.data) return; // Ensure data is available before proceeding

      const seen = new Set();
      const uniqueStages = this.data.filter(item => {
        const duplicate = seen.has(item.location);
        seen.add(item.location);
        return !duplicate;
      });

      this.stages = uniqueStages.map((item, index) => ({
        id: index + 1,
        name: item.location,
        link: `/24fintech2024/stage/${item.location
          .toLowerCase()
          .replace(/ /g, "-")}`
      }));
    }
  }
};
</script>

<style scoped>
/* Scoped styling here or link existing styles */
</style>
