
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class SFF2021CaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    finalImage: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-SFF-1.png",
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2.png",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF3.png",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF5.png",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF6.png"
  };
  links: { [id: string]: string } = {
    webLibrary: "http://sff.thoth.art",
    ideaInk: "http://www.ideaink.co",
    sff: "https://www.fintechfestival.sg",
    mas: "https://www.mas.gov.sg"
  };
  goToArticle() {
    window.location.href = this.links.webLibrary;
  }
}
