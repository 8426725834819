<template>
  <div class="section-3 wf-section">
    <div class="div-block-42 top-align">
      <div style="opacity: 1;" class="div-block-43">
        <img
          src="https://uploads-ssl.webflow.com/6172759cf06acb8c26bd6802/6178d2281126fa959b60fe6d_Thoth__wordmark_blue_200px.png"
          loading="lazy"
          width="160"
          alt=""
          class="image-26"
        />
        <h3 class="text-block-36">
          Turn long text to 1-page visuals, in just 3 steps.<br />
        </h3>
        <div class="text-block-31">
          Thoth.ai automates our hand-drawn craft into a fun and fuss-free
          digital service.<br />‍<br />Curious? Try our demo. <br /><br />Even
          better, sign up for our exclusive waitlist now.<strong></strong><br />
        </div>

        <div class="div-block-54">
          <a
            href="mailto:hello@thoth.art"
            target="_blank"
            class="button-3 black w-button"
            >CONTACT US</a
          >
          <a
            href="https://form.typeform.com/to/Tbyclmz4"
            target="_blank"
            class="button-3 yellow-outline yellow w-button"
            >JOIN WAITLIST</a
          >
        </div>
      </div>
      <div
        style="padding-top: 56.1702%; opacity: 1;"
        class="w-embed-youtubevideo youtube"
      >
        <iframe
          src="https://www.youtube.com/embed/PnstsGyBBdE?si=Gj9pZ7CUpHs1HeKY?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
          frameborder="0"
          style="position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:auto"
          allow="autoplay; encrypted-media"
          allowfullscreen=""
          title="Thoth.ai | Automate text content into 1-page visuals"
        ></iframe>
      </div>
    </div>

    <!-- <div class="div-block-42 no-margin">
            <div style="opacity: 1; transform: translate3d(0px, 0px, 0px);" class="div-block-58">
                <div class="text-block-36 center">(P.S. Check out our other projects happening this week too.)<br></div>
                <div class="div-block-59">
                    <img src="https://uploads-ssl.webflow.com/6172759cf06acb8c26bd6802/6187fcee80976abb31f8c71f_SWITCH%20Wall.jpeg"
                        loading="lazy" height="200"
                        sizes="(max-width: 479px) 88vw, (max-width: 991px) 43vw, 355.0546875px" alt="" class="image-29">
                    <img src="https://uploads-ssl.webflow.com/6172759cf06acb8c26bd6802/6187fd2216a41fed2bf59072_Web%20Stories%20-%20MAS%203.gif"
                        loading="lazy" height="200" alt="" class="image-28">
                    <div>
                        <h6 class="heading-29">Switch Singapore</h6>
                        <div class="text-block-45">IOT Sensor Kit & Thoth.ai summaries</div>
                        <a href="https://www.ideaink.co/sffxswitch2020-switch" target="_blank" class="link-12">Check out
                            our
                            2020 Case Study</a>
                    </div>
                    <div>
                        <h6 class="heading-29">MAS Talent Pavilion</h6>
                        <div class="text-block-45">Customized user journeys</div>
                        <a href="https://www.talentpavilion.sg/home" target="_blank" class="link-12">View site</a>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  }
};
</script>
