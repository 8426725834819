<template>
  <div>
    <!-- Main Section -->
    <div class="section traveltellers_template">
      <!-- Search Form -->
      <form action="/24fintech2024/search" class="search searchindivpost searchindivpostmobile w-form">
        <div class="search-input-container">
          <input class="search-input w-input" maxlength="256" name="query" placeholder="Search..." type="search"
            id="search" required />
          <input type="submit" class="search-button-2 w-button" value="⌕" />
        </div>
      </form>
      <div class="blog_content_contain">
        <!-- Blog Title Section -->
        <div class="blog_title_contain">
          <a :href="`${content.stageLink}`" class="link-block-17 w-inline-block">
            <div :style="{ backgroundColor: getCategoryColor(content.category) }"
              class="text-block-5 smaller _background">
              {{ content.category }}
            </div>
          </a>
          <h2 class="blog-post-title">{{ content.heading }}</h2>
          <div class="div-block-21">
            <div class="_2020_theme-day">DAY/TIMING (SAST)</div>
            <div class="_2020_talk-time">
              {{ formatDate(content.date) }} {{ content.time }}
            </div>
            <!-- Display the formatted date and time -->
            <div class="text-block-57">{{ content.timeDetail }}</div>
            <!-- Additional info if needed -->
          </div>
          <div id="center-linebreak" class="div-block-40"></div>
          <div class="div-block-namespartners">
            <div class="text_block">{{ content.description }}</div>
          </div>
          <div id="center-linebreak" class="div-block-40"></div>
          <div class="div-block-namespartners">
            <div class="text_block speakers">
              <!-- Loop through the speakers array and display their information -->
              <div v-for="(speaker, index) in content.speakers" :key="index">
                <strong>{{ speaker.name }}</strong> | {{ speaker.designation }},
                {{ speaker.company }}<br />
              </div>
              <div v-if="content.moderators">
                <div>
                  <br><strong>Moderator:</strong>
                </div>
                <div v-for="(m, index) in content.moderators" :key="index">
                  <strong>{{ m.name }}</strong> | {{ m.designation }},
                  {{ m.company }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Blog Content Section -->
        <a href="javascript:void(0)" class="lightbox-link w-inline-block w-lightbox" aria-label="open lightbox"
          aria-haspopup="dialog" @click="openModal(content.imageUrl)">
          <div v-if="content.imageUrl">
            <img alt="" loading="lazy" :src="content.imageUrl" sizes="(max-width: 479px) 0px, 100vw"
              class="responsive-blog-image" />
          </div>
          <div v-else class="div-block-82">
            <img src="https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/images/Holding+Slide.png"
              loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="" class="image-39 responsive-blog-image " />
          </div>
        </a>

        <!-- Modal Component -->
        <image-modal v-if="imageUrl" :imageUrl="imageUrl" ref="imageModal"></image-modal>

        <!-- Share This Page Section -->
        <div class="div-block-17">
          <div class="div-block-64-copy">
            <div class="text-block-47">
              This is some text inside of a div block.
            </div>
            <a href="#" class="link-block-24 w-inline-block"><img
                src="https://uploads-ssl.webflow.com/6172759cf06acb8c26bd6802/61880c59db2b2e6a5c6cb0b0_thothai_blue.png"
                loading="lazy" width="100" height="20" alt="" sizes="100px" class="thoth-logo" /></a>

            <h6 class="share-this-page">SHARE THIS PAGE</h6>
            <div class="html-embed-2 w-embed">
              <!-- AddToAny BEGIN -->
              <a :href="`https://www.addtoany.com/share#url=thoth.art/${content.talkEndpoint}&amp;title=${content.name}`
                " target="_blank"><img :src="Plus_Yellow_icon" width="32" height="32" /></a>

              <a :href="`https://www.addtoany.com/add_to/facebook?linkurl=thoth.art/${content.talkEndpoint}&amp;linkname=${content.name}`
                " target="_blank"><img :src="Facebook_onLight_icon" width="32" height="32" /></a>

              <a :href="`https://www.addtoany.com/add_to/x?linkurl=thoth.art/${content.talkEndpoint}&amp;linkname=${content.name}`
                " target="_blank"><img :src="X_onLight_icon" width="32" height="32" /></a>

              <a :href="`https://www.addtoany.com/add_to/linkedin?linkurl=thoth.art/${content.talkEndpoint}&amp;linkname=${content.name}`
                " target="_blank"><img :src="LinkedIN_onLight_icon" width="32" height="32" /></a>

              <a :href="`https://www.addtoany.com/add_to/whatsapp?linkurl=thoth.art/${content.talkEndpoint}&amp;linkname=${content.name}`
                " target="_blank"><img :src="WhatsApp_onLight_icon" width="32" height="32" /></a>

              <a :href="`https://www.addtoany.com/add_to/telegram?linkurl=thoth.art/${content.talkEndpoint}&amp;linkname=${content.name}`
                " target="_blank"><img :src="Telegram_onLight_icon" width="32" height="32" /></a>

              <!-- AddToAny END -->
            </div>
            <div class="text-block-48">
              You are free to share all images for personal purposes, on social
              media and networking accounts such as LinkedIn, Twitter and
              Instagram, provided always that you give appropriate credit and
              attribution to Thoth and Idea Ink. You may not share images
              without the appropriating credit and attribution, and you may not
              share images for commercial or business purposes. Save for the
              revocable licence to use Thoth, all rights are reserved. Failure
              to comply may result in legal proceedings being initiated without
              further reference to you.
            </div>
          </div>
        </div>
      </div>

      <!-- Other Sessions Section -->
      <div class="containers ">
        <div class="div-block-23 ">
          <h6 class="heading-5">OTHER sessions on</h6>
          <h6 class="heading-5">{{ formatDate(content.date) }}</h6>
        </div>

        <div class="blog-collection-list-wrapper">
          <div v-if="lightboxData.items.length > 0" role="list" class="other_post_collection_list w-dyn-items">
            <!-- Loop through the top 4 talks -->
            <div v-for="(talk, index) in lightboxData.items" :key="index" role="listitem"
              class="other-posts-item w-dyn-item">
              <div class="div-block-66">
                <div class="div-block-67">
                  <div v-if="talk.imageUrl">
                    <img :src="talk.imageUrl" sizes="(max-width: 479px) 100vw, 100vw" loading="lazy" alt="Stage Image"
                      class="image-24 responsive-image" />
                  </div>
                  <div v-else style="overflow: auto;">
                    <img
                      src="https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/images/Holding+Slide.png"
                      loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image"
                      class="image-39 responsive-image" />
                  </div>
                  <div class="story_content vertical">
                    <div class="div-block-27">
                      <div class="text-block-20">
                        {{ formatDate(talk.date) }}
                      </div>
                      <div class="text-block-20 text-block-7-time">
                        {{ talk.time }}
                      </div>
                    </div>
                    <h4 class="on-this-day-h4-2">{{ talk.title }}</h4>
                    <div class="rich-text-block-6 w-richtext ">
                      <p v-for="(speaker, index) in talk.speakers" :key="index">
                        <strong>{{ speaker.name }}</strong> |
                        {{ speaker.designation }}, {{ speaker.company }}<br />
                      </p>
                    </div>
                  </div>
                  <div class="template-content-2 dark no_opacity">
                    <router-link :to="generateEndpoint(talk.title)" class="template-link w-inline-block"></router-link>
                    <div class="template-title-wrapper large-margin">
                      <h6 class="body1 white heading-session-highlights">
                        {{ talk.title }}
                      </h6>

                      <div class="rich-text-block-4 pink w-richtext ">
                        <div>{{ talk.description }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-state-5 w-dyn-empty">
            <div>No items found.</div>
          </div>
        </div>
      </div>
      <div class="view_all_link">
        <div class="line"></div>
        <router-link :to="`/24fintech2024/day/${content.day}`" class="link-block-8 w-inline-block">
          <div class="text-block-13">view all</div>
          <div class="link-block-arrow-wrapp">
            <img src="https://uploads-ssl.webflow.com/6172759cf06acb8c26bd6802/6172759cf06acb2b2bbd6848_arrow.svg"
              loading="lazy" width="4" alt="" />
          </div>
        </router-link>
      </div>
    </div>

    <!-- Footer Section -->
    <div class="section footer_section">
      <div class="footer">
        <div class="container">
          <!-- Footer Content Goes Here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageModal from "./ImageModal.vue";
import X_onLight_icon from "../images/X_onLight.svg";
import WhatsApp_onLight_icon from "../images/WhatsApp_onLight.svg";
import Telegram_onLight_icon from "../images/Telegram_onLight.svg";
import LinkedIN_onLight_icon from "../images/LinkedIN_onLight.svg";
import Plus_Yellow_icon from "../images/Plus_Yellow.svg";
import Facebook_onLight_icon from "../images/Facebook_onLight.svg"


export default {
  name: "Talk",
  props: {
    talkName: {
      type: String,
      required: true
    }
  },
  components: {
    ImageModal
  },
  data() {
    return {
      content: {}, // Store the content for the current talk
      lightboxData: {
        items: [],
        group: ""
      },
      imageUrl: null,
      data: null,
      WhatsApp_onLight_icon,
      X_onLight_icon,
      Telegram_onLight_icon,
      LinkedIN_onLight_icon,
      Plus_Yellow_icon,
      Facebook_onLight_icon
    };
  },

  methods: {
    openModal(url) {
      if (url) {
        this.imageUrl = url;
        this.$refs.imageModal.open(); // Opens the modal
      }
    },
    getCategoryColor(category) {
      const colors = {
        "EXECUTIVE SUMMIT": "#231f20",
        "FUTURES FORUM": "#95733b",
        "FINTECH FUSION": "#19C8E1",
        "24\u00b0 TRENDS": "#005430"
        // Add more categories and their colors as needed
      };
      return colors[category] || "#000000"; // Default color if category not found
    },
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        // Once data is fetched, load the talk data
        this.loadTalkData(this.$route.params.name);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    generateEndpoint(title) {
      return `/24fintech2024/talk/${title
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .trim()}`; // Trim any extra whitespace
    },
    loadDataBasedOnRoute(route) {
      // You could split the path and decide based on segments of the URL
      const pathSegments = route.path.split("/");
      if (pathSegments[2] === "day") {
        this.loadDayData(pathSegments[3]); // Assuming the day number follows /day/ in the path
        return;
      } else if (pathSegments[2] === "talk") {
        this.loadTalkData(pathSegments[3]); // Load specific talk data
      }
    },
    loadDayData(day) {
      // Logic to load data for the entire day
      this.content = this.data.filter(item => item.day === day);
    },
    loadTalkData(talkName) {
      if (!this.data) return; // Ensure data is available before proceeding

      const matchedTalk = this.data.find(
        item => item.talkEndpoint === `/24fintech2024/talk/${talkName}`
      );

      if (matchedTalk) {
        this.content = {
          name: matchedTalk.title,
          category: matchedTalk.location,
          date: matchedTalk.date,
          time: matchedTalk.time,
          timeDetail: `Duration: ${matchedTalk.duration} minutes`,
          heading: matchedTalk.title,
          speakers: matchedTalk.speakers,
          moderators: matchedTalk.moderators,
          day: matchedTalk.day,
          imageUrl: matchedTalk.imageUrl,
          talkEndpoint: matchedTalk.talkEndpoint,
          description: matchedTalk.description,
          stageLink: `/24fintech2024/stage/${matchedTalk.location
            .toLowerCase()
            .replace(/ /g, "-")}`
        };
      }
      const matchedDay = this.data.filter(
        item =>
          item.date === matchedTalk.date &&
          item.talkEndpoint !== matchedTalk.talkEndpoint
      );
      const top4Talks = matchedDay
        .sort((a, b) => b.rating - a.rating) // Sort by rating in descending order
        .slice(0, 4); // Select the first 4 items
      this.lightboxData.items = top4Talks;
    },
    formatDate(date) {
      // Format the date as "dd MMM"
      const options = { day: "2-digit", month: "short" };
      return new Date(date).toLocaleDateString("en-US", options);
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.path !== oldRoute.path) {
        this.loadDataBasedOnRoute(newRoute);
      }
    }
  },

  async mounted() {
    await this.fetchData();
  }
};
</script>

<style scoped>
.responsive-image {
  width: 100%;
  /* Fill the width of the container */
  height: 200px;
  /* Set a specific height */
  object-fit: contain;
}

.responsive-blog-image {
  width: 100%;
  height: auto;
  max-height: 1080px;
  object-fit: contain;

}
</style>
