<template>
  <!-- Popup Form -->
  <div v-if="showPopup" class="popup-overlay" @click.self="showPopup = false">
    <div class="popup-content" @click.stop>
      <button class="close" @click="showPopup = false">×</button>
      <h2 class="text-block-38">Get Your Free PDF</h2>
      <form @submit.prevent="submitForm">
        <div class="input-group">
          <input type="text" placeholder="First Name" v-model="firstName" required />
          <p v-if="submitted && !firstName" class="error">
            First name is required.
          </p>
        </div>
        <div class="input-group">
          <input type="text" placeholder="Last Name" v-model="lastName" required />
          <p v-if="submitted && !lastName" class="error">
            Last name is required.
          </p>
        </div>
        <div class="input-group">
          <input type="email" placeholder="Enter your email address" v-model="email" required />
          <p v-if="submitted && !email" class="error">
            Email is required.
          </p>
        </div>
        <div class="input-group">
          <input type="checkbox" id="privacyCheck" v-model="agreedToPrivacy" />
          <label class="text-block-57" for="privacyCheck">I agree to how my data is used by the website in
            accordance with the
            <a href="https://thoth.art/privacy" target="_blank" class="link-6">privacy policy</a>.</label>

        </div>
        <p v-if="submitted && !agreedToPrivacy" class="error">
          You must agree to the privacy policy.
        </p>
        <button type="submit" class="btn-submit ">SEND ME</button>
      </form>
    </div>
  </div>

  <div class="navbar w-nav" data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1"
    data-easing="ease" data-easing2="ease">
    <div class="div-block-65">
      <div class="container">
        <router-link to="/24fintech2024" aria-current="page" class="brand w-nav-brand">
          <img src="../images/24f-thoth-partner-lockup-horizontal.svg" alt="" width="180" class="image-41" />
        </router-link>
        <div class="right_side_navbar">
          <nav role="navigation" class="nav-menu w-nav-menu">
            <router-link :to="{ path: '/24fintech2024' }" :class="{
              'navlinks w-nav-link ': true,
              'w--current': isActiveRoute('/24fintech2024')
            }">
              HOME
            </router-link>

            <!-- Dropdown for Summaries -->
            <div class="dropdown w-dropdown" @mouseover="openDropdown" @mouseleave="closeDropdown">
              <div class="dropdown-toggle w-dropdown-toggle">
                <div class="nav_link_text navsessions">SUMMARIES</div>
                <div class="nav_link_hover_div"></div>
              </div>

              <!-- Dropdown content -->
              <nav class="dropdown-list w-dropdown-list" v-if="isDropdownOpen">
                <router-link :to="{ path: '/24fintech2024/day/1' }" :class="{
                  'nav_link dropdown w-inline-block': true,
                  'w--current': isActiveRoute('/24fintech2024/day/1')
                }">
                  <div class="nav_link_text ">DAY 1</div>
                </router-link>
                <router-link :to="{ path: '/24fintech2024/day/2' }" :class="{
                  'nav_link dropdown w-inline-block': true,
                  'w--current': isActiveRoute('/24fintech2024/day/2')
                }">
                  <div class="nav_link_text">DAY 2</div>
                </router-link>
                <router-link :to="{ path: '/24fintech2024/day/3' }" :class="{
                  'nav_link dropdown w-inline-block': true,
                  'w--current': isActiveRoute('/24fintech2024/day/3')
                }">
                  <div class="nav_link_text">DAY 3</div>
                </router-link>
              </nav>
            </div>

            <router-link :to="{ path: '/24fintech2024/agenda' }" :class="{
              'navlinks w-nav-link': true,
              'w--current': isActiveRoute('/24fintech2024/agenda')
            }">
              AGENDA
            </router-link>
            <router-link :to="{ path: '/24fintech2024/about' }" :class="{
              'navlinks w-nav-link': true,
              'w--current': isActiveRoute('/24fintech2024/about')
            }">
              ABOUT
            </router-link>
          </nav>
          <div class="sign_in_up_wrap">
            <button @click="showPopup = true" class="mail-button w-button" id="pdfButton">
              Get a Compiled PDF
            </button>
          </div>
        </div>


        <div class="menu-button w-nav-button" @click="toggleMobileMenu">
          <img src="../images/5f89831818dcb71775e1b3ea_hamburger-white.svg" width="30" alt="" class="image-15" />
        </div>
      </div>

      <!-- Mobile Menu Popup -->
      <transition name="slide-fade">
        <div v-if="isMobileMenuOpen" class="dropdown-list w-dropdown-list ">
          <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="height: 5383px; display: block;">
            <nav role="navigation" class="nav-menu w-nav-menu space-y-2 gap-y-4 align "
              style="transition: all, transform 400ms; transform: translateY(0px) translateX(0px);"
              data-nav-menu-open="">
              <div class="flex flex-col space-y-2 gap-y-4">
                <router-link :to="{ path: '/24fintech2024' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024')
                }">
                  HOME
                </router-link>
                <router-link :to="{ path: '/24fintech2024/day/1' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024/day/1')
                }">
                  DAY 1
                </router-link>
                <router-link :to="{ path: '/24fintech2024/day/2' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024/day/2')
                }">
                  DAY 2
                </router-link>
                <router-link :to="{ path: '/24fintech2024/day/3' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024/day/3')
                }">
                  DAY 3
                </router-link>
                <router-link :to="{ path: '/24fintech2024/agenda' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024/agenda')
                }">
                  AGENDA
                </router-link>
                <router-link :to="{ path: '/24fintech2024/about' }" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': isActiveRoute('/24fintech2024/about')
                }">
                  ABOUT
                </router-link>
                <!-- get a compiled pdf -->
                <a @click="showPopup = true" :class="{
                  'navlinks w-nav-link': true,
                  'w--current': showPopup
                }">GET A COMPILED PDF</a>
              </div>
            </nav>
          </div>
        </div>
      </transition>
    </div>
    <div class="div-block-62">
      <a href="https://form.typeform.com/to/Tbyclmz4" target="_blank" class="link-block-16 w-inline-block">
        <div class="text-block-46">JOIN</div>
        <img src="../images/thothai_logo_white-p-500.png" loading="lazy" sizes="79.98046875px" height="16" alt=""
          srcset="
            ../images/thothai_logo_white-p-500.png   500w,
            ../images/thothai_logo_black-p-800.png   800w,
            ../images/thothai_logo_black-p-1080.png 1080w,
            ../images/thothai_logo_black-p-1600.png 1600w,
            ../images/thothai_logo_black.png        2320w
          " />
        <div class="text-block-46 left">WAITLIST</div>
      </a>
    </div>
  </div>
</template>


<script>
import { MONDAY_BOARD_ID, MONDAY_API_KEY } from "@/services";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
export default {
  data() {
    return {
      isDropdownOpen: false, // State to manage dropdown visibility
      isMobileMenuOpen: false, // State to manage mobile menu popup
      showPopup: false,
      firstName: "",
      lastName: "",
      email: "",
      agreedToPrivacy: false,
      submitted: false,
      submitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.submitted = true;
      if (this.submitting) {
        return;  // Prevent multiple submissions
      }
      if (this.validateForm()) {
        this.submitting = true;
        try {
          // Prepare the column values
          const columnValues = JSON.stringify({
            lead_email: {
              email: this.email,
              text: this.email,
            },
          });

          // Construct the GraphQL mutation
          const query = `
      mutation {
        create_item(
          board_id: ${MONDAY_BOARD_ID},
          item_name: "${this.firstName} ${this.lastName}",
          column_values: "${columnValues.replace(/"/g, '\\"')}"
        ) {
          id
        }
      }
    `;

          // Make the API request to Monday.com
          const response = await fetch("https://api.monday.com/v2", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${MONDAY_API_KEY}`,
            },
            body: JSON.stringify({ query }),
          });

          const data = await response.json();

          if (data.errors) {
            console.error("API Error:", data.errors);
            return new Response(
              JSON.stringify({
                error: "Failed to create item in Monday.com",
                details: data.errors,
              }),
              {
                status: 500,
              },
            );
          }
          toastr.success('Form submission was successful!');
          this.showPopup = false;

          return new Response(
            JSON.stringify({
              message: "Form submission was successful",
              itemId: data.data.create_item.id,
            }),
            { status: 200, headers: { "Content-Type": "application/json" } },
          );

        } catch (error) {
          console.error("Error pushing to Monday.com:", error.message);
          toastr.warning("Opps, something went wrong. Please try again later");
          return new Response("Failed to subscribe, please try again later", {
            status: 500,
          });
        } finally {
          // Set a timeout before allowing another submission
          setTimeout(() => {
            this.submitting = false;  // Reset the submitting state after 3 seconds
          }, 3000);
        }
      }
    },
    validateForm() {
      return this.firstName && this.lastName && this.email && this.agreedToPrivacy;
    },
    isActiveRoute(routePath) {
      return this.$route.path === routePath;
    },
    toggleMobileMenu(event) {
      event.stopPropagation(); // Prevents the event from bubbling up to document
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (this.isMobileMenuOpen) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
        this.isMobileMenuOpen = false; // Ensure the mobile menu is closed
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    openDropdown() {
      this.isDropdownOpen = true;
    },

    closeDropdown() {
      this.isDropdownOpen = false;
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>


<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 400px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  gap: 10px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-color: "#95733b";
}

.btn-submit {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.link-privacy {
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #0066cc;
  text-decoration: none;
}

.link-privacy:hover {
  text-decoration: underline;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 50px;
  max-width: 100%;
  flex-wrap: nowrap;
}

.nav-menu {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
}

.dropdown {
  position: relative;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
}


.menu-button {
  cursor: pointer;
}

/* Mobile menu popup */
.mobile-menu-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-list {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
  max-width: 300px;
}

/* Animation for the mobile menu */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
  }

  .menu-button {
    display: block;
  }
}
</style>
