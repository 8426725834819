
import { Vue, Options } from "vue-class-component";
import { defineRule, Form } from "vee-validate";
import api from "@/services/api";
import UIkit from "uikit";
import TextField from "@/components/common/TextField.vue";
import PasswordField from "@/components/common/PasswordField.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { SIGNUP_API } from "@/services";

defineRule("required", email => {
  if (!email || !email.length) {
    return "Email is required.";
  } else {
    return true;
  }
});
defineRule("email", email => {
  if (!email || !email.length) {
    return true;
  }
  if (!/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email)) {
    return "This field must be a valid email";
  }
  return true;
});

@Options({
  components: {
    TextField,
    PasswordField,
    ThothButton,
    Form,
    Modal
  }
})
export default class Signup extends Vue {
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  thothLogo = STATIC_ASSET_IMAGES_BASE_URL + "/thoth-logo.png";
  waitlistModalImage =
    STATIC_ASSET_IMAGES_BASE_URL + "/waitlist-modal-image.png";
  emailRules = "required|email";
  inviteCode = "";
  emailError = "";
  signupIsSuccessful = false;

  get noInviteCode() {
    return this.inviteCode.trim() === "";
  }

  get modalTitle() {
    return this.noInviteCode
      ? "Join our waitlist"
      : this.signupIsSuccessful
      ? "Check your email"
      : "Something went wrong!";
  }

  get modalMessage() {
    const successMessage = `We've sent you an email with instructions to verify your email address.`;
    const noInviteLinkError = `We're currently in closed Alpha! Join our waitlist, and we will be in touch when spaces become available.`;
    const wrongInviteLinkError = `This link has already been used or expired. If you think there has been a mistake, please contact us at <b>hello@thoth.art</b>.`;
    return this.inviteCode.trim() === ""
      ? noInviteLinkError
      : this.signupIsSuccessful
      ? successMessage
      : wrongInviteLinkError;
  }

  mounted() {
    this.inviteCode = (this.$route.query.inviteCode as string) ?? "";
  }

  resetEmailError() {
    if (this.emailError !== "") {
      this.emailError = "";
    }
  }

  openModal() {
    UIkit.modal("#signup__modal").show();
  }

  closeModal() {
    UIkit.modal("#signup__modal").hide();
    setTimeout(() => {
      this.signupIsSuccessful = false;
    }, 500);
  }

  goToWaitlist() {
    this.$router.push({ name: "Waitlist" });
  }

  createAccount(form) {
    // Show error modal if no invite code
    if (this.inviteCode.trim() == "") {
      this.openModal();
      return;
    }

    const signupForm = {
      email: form.Email,
      password: form.Password
    };

    api
      .post(`${SIGNUP_API}?invite_code=${this.inviteCode}`, signupForm)
      .then(response => {
        if (response.status === 201) {
          this.signupIsSuccessful = true;
          this.openModal();
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          // Invite code invalid/used/expired
          this.openModal();
        } else if (error.response.status === 400) {
          // Email already taken
          this.emailError = "Email address is already taken.";
        }
      });
  }
}
