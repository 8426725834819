<template>
  <div class="section traveltellers_template">
    <div class="container tags_heading w-container ">
      <div class="div-block-38">
        <h2>Agenda</h2>
        <div class="text-block-16">
          Click on talk names to see the visual summaries. <br />
          For the full agenda, please visit the
          <a href="https://24fintech.com/agenda" target="_blank" class="link-7">24FINTECH SITE</a>.
        </div>
      </div>
    </div>

    <!-- Agenda Tabs Section -->
    <div class="container blog_posts agendaextra w-container">
      <div class="div-block-16">
        <div class="w-tabs" data-duration-in="300" data-duration-out="100">
          <div class="tabs-menu-2 w-tab-menu" role="tablist">
            <!-- Tab Links -->
            <a class="tab-link w-inline-block w-tab-link" :class="{ 'w--current': activeDay === '1' }"
              @click="loadDayData('1')">
              <div class="text-block-39">DAY 1</div>
            </a>
            <a class="tab-link w-inline-block w-tab-link" :class="{ 'w--current': activeDay === '2' }"
              @click="loadDayData('2')">
              <div class="text-block-49">DAY 2</div>
            </a>
            <a class="tab-link w-inline-block w-tab-link" :class="{ 'w--current': activeDay === '3' }"
              @click="loadDayData('3')">
              <div>DAY 3</div>
            </a>
          </div>

          <div class="tabs-content-2 w-tab-content">
            <!-- Dynamic Tab Content -->
            <div v-if="contents.length > 0">
              <div class="agenda-pane w-tab-pane w--tab-active">
                <div class="w-layout-grid grid-10">
                  <h6 class="agenda-table-heading">DAY/time (SAST)</h6>
                  <h6 class="agenda-table-heading">Session</h6>
                </div>
                <div class="w-dyn-list">
                  <div role="list" class="w-dyn-items">
                    <!-- Session Items -->
                    <div v-for="content in contents" :key="content.name" role="listitem" class="w-dyn-item">
                      <div class="w-layout-grid grid-5">
                        <div class="div-block-24">
                          <div class="text-block-14-day">
                            {{ formatDate(content.date) }}
                          </div>
                          <div class="text-block-14">{{ content.time }}</div>
                        </div>
                        <router-link :to="generateEndpoint(content.name)" class="link-block-11 w-inline-block">
                          <div class="text-block-15">{{ content.name }}</div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Fallback when no data is available -->
            <div v-else>
              <p>No sessions available for this day.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky-nav-contain">
        <form action="/24fintech2024/search" class="search searchagenda w-form">
          <input class="search-input searchagendatext w-input" maxlength="256" name="query" placeholder="Search..."
            type="search" id="search" required />
          <input type="submit" class="search-button w-button" value="⌕" />
        </form>
        <div class="sticky">
          <h6 class="browse-headings">BROWSE BY session type</h6>
          <div class="line div stickynavline"></div>
          <div class="blog_categories_contain">
            <div class="w-dyn-list">
              <div role="list" class="category-tags-collection-list w-dyn-items">
                <div role="listitem" v-for="(location, index) in uniqueLocations" :key="index" class="w-dyn-item">
                  <router-link :to="`/24fintech2024/stage/${location
                    .toLowerCase()
                    .replace(/ /g, '-')}`
                    " class="link-block-14 w-inline-block">
                    <div class="text-block-43">{{ location }}</div>
                  </router-link>
                </div>
              </div>
              <div class="w-dyn-empty" v-if="!uniqueLocations.length">
                <div>No items found.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Content or Widgets Can Go Here -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AgendaSection",
  data() {
    return {
      activeDay: "1", // Default to Day 1
      contents: [], // Store the filtered sessions for the selected day
      uniqueLocations: [],
      data: null // Initialize data as null to hold fetched data
    };
  },
  watch: {
    activeDay: {
      immediate: true, // Run this on initial load
      handler() {
        this.loadDayData(this.activeDay); // Load data for the active day
      }
    }
  },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        this.loadDayData(this.activeDay); // Load the data once fetch is complete
        this.loadUniqueLocations(); // Load unique locations after data is fetche
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    generateEndpoint(title) {
      return `/24fintech2024/talk/${title
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .trim()}`; // Trim any extra whitespace
    },
    loadUniqueLocations() {
      if (!this.data) return; // Ensure data is available before proceeding

      // Extract unique locations from the data
      const seen = new Set();
      this.uniqueLocations = this.data
        .filter(item => {
          const duplicate = seen.has(item.location);
          seen.add(item.location);
          return !duplicate;
        })
        .map(item => item.location); // Ensure you extract only locations
    },
    formatDate(date) {
      // Format the date as "dd MMM"
      const options = { month: "short", day: "2-digit" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    loadDayData(dayName) {
      if (!this.data) return; // Ensure data is available before proceeding

      this.activeDay = dayName;

      // Filter the data based on the selected day
      const filteredData = this.data.filter(
        item => item.day.toString() === dayName
      );

      // Map the filtered data to the contents structure
      this.contents = filteredData.map(item => ({
        name: item.title,
        category: item.location,
        imageUrl: item.imageUrl,
        time: item.time,
        timeDetail: `Duration: ${item.duration} minutes`,
        heading: item.title,
        description: item.description,
        link: item.source,
        speakers: item.speakers,
        date: item.date
      }));
    }
  },
  async mounted() {
    await this.fetchData(); // Fetch data when the component is mounted
  }
};
</script>
