
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";

@Options({
  components: {
    NavBar,
    Footer
  }
})
export default class PrivacyPolicy extends Vue {
  isLoggedIn = false;
  loaded = false;
  dateOfUpdate = "30 March 2022";

  introduction = [
    `Welcome to Thoth.
    \nThoth operates ${this.getStyledLink(
      "https://thoth.art"
    )} (hereinafter referred to as "Service").
    \nOur Privacy Policy governs your visit to ${this.getStyledLink(
      "https://thoth.art"
    )}("Website"), and explains how Thoth and its Affilates ("Thoth", "us", "we", or "our") collects, uses, stores, processes and discloses information that results from your use of our Service.
    \nIn this Privacy Policy, "Affiliate" means, in relation to Thoth, any entity that controls, is under the control of, or is under common control with Thoth, where control means the direct or indirect ownership of more than 50 per cent of the voting capital or similar right of ownership of that party or the legal power to direct or cause the direction of the general management and policies of that party whether through the ownership of voting capital, by contract or otherwise.
    \nBy using the Website and/ or Service or providing us with your data, you agree to the collection, use and disclosure of information in accordance with this Privacy Policy. In particular, by registering with us, you agree to provide us your data as may be reasonably required for the purpose of accessing our Service. Such agreement grants us the right to process your data, subject to the terms of this Privacy Policy. If you do not consent to the terms of this Privacy Policy, please withdraw your consent in accordance with this Privacy Policy and do not access this Website and/ or the Service.
    \nThis Privacy Policy supplements but does not supersede nor replace any other consents you may have previously provided to us, nor does it affect any rights we may have at law in connection with the collection, use or disclosure of your Personal Data, including the collection, usage and disclosure of your Personal Data without your consent where permitted or required by the Personal Data Protection Act 2012 (Singapore) ("PDPA") or other applicable laws.
    \nThis Privacy Policy has been developed and implemented to meet our obligations under the laws of Singapore. We will use commercially reasonable endeavours to ensure our compliance with all applicable laws of any other jurisdiction, including but not limited to the laws of the European Union and the United States of America, in respect of the protection of your data.
    \nUnless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
    \nOur Terms of Service ("Terms", "Terms of Service") govern all use of our Service and together with the Privacy Policy constitutes your agreement with us ("agreement"). Please read it here:  ${this.getStyledLink(
      "https://thoth.art/tos"
    )}.`
  ];
  definitions = [
    {
      term: "service",
      definition: `means the ${this.getStyledLink(
        "https://thoth.art"
      )} website operated by Thoth.`
    },
    {
      term: "cookies",
      definition: `are small files stored on your device (computer or mobile device).`
    },
    {
      term: "data controller",
      definition: `means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.`
    },
    {
      term: "data processors (or service providers)",
      definition: `means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.`
    },
    {
      term: "data subject",
      definition: `is any living individual who is the subject of Personal Data.`
    },
    {
      term: "the user",
      definition: `is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.`
    }
  ];
  content = [
    {
      title: "Information Collection and Use",
      texts: [
        `We collect several different types of information for various purposes to provide and improve our Service to you.`
      ]
    },
    {
      title: "Types of Data Collected",
      texts: [
        `${this.getStyledSubtitle("Personal Data")} \n
        "Personal Data" refers to personally identifiable information, including but not limited to:
        \n(a) contact details, such as your name, telephone number, email address and other personal details;
        \n(b) payment-related information, such as bank account details or credit card information;
        \n(c) purchase and order history; and
        \n(d) other personally identifiable information which you provide and/ or which is generated (including any graphics, text, audio, video or other asset) in connection with your use of the Website and/ or Service, including but not limited to any request to contact you or through your usage of Service.
        \n${this.getStyledSubtitle(
          "Usage Data"
        )} \nWe may also collect information that your browser or device sends ("Usage Data").
        \nThis Usage Data may include information such as your computer's Internet Protocol address, browser type, browser version, cookies, pages you visit, time and date of visit, time spent on pages, computer and connection information, network information, unique device identifiers, device capability, bandwidth, and other diagnostic data.
        \nWhen you access our Service with a device, this Usage Data may include information such as the type of device you use, your device's unique identification numbers or Internet Protocol address, operating system, Internet browser you use, cookies, network information, and other diagnostic data."`
      ]
    },
    {
      title: "Third Party Data",
      texts: [
        `If you provide Personal Data of a third party to us (e.g. next-of-kin information), you confirm that you are duly authorised by that third party to do so for the purposes for which you have provided that Personal Data to us.`
      ]
    },
    {
      title: "Purpose for Collection, Use and Disclosure",
      texts: [
        `Generally, we collect, use and/ or disclosure your Personal Data or Usage Data for the following purposes:
        \n(a) to provide, maintain and improve our Service and functions on the Website;
        \n(b) to process and maintain your account with us;
        \n(c) to notify you about changes to our Service;
        \n(d) to allow you to participate in interactive features of our Service when you choose to do so;
        \n(e) to personalise your web user experience on the Website;
        \n(f) to process, record, monitor, deliver and/ or otherwise fulfil your order(s);
        \n(g) to verify and process your personal details and payment;
        \n(h) to provide customer support; including but not limited to responding to your feedback, queries, requests, and complaints;
        \n(i) to gather analysis or valuable information about the Service;
        \n(j) to monitor the usage of the Service;
        \n(k) to detect, prevent and address technical issues and back up our systems to provide for disaster recovery;
        \n(l) to enforce our Terms;
        \n(m) to fulfill any other purpose agreed by or notified to you;
        \n(n) to carry out our obligations and enforce our rights arising from any contracts entered into between you and us;
        \n(o) to provide you with notices about your account and/ or subscription, including   expiration and renewal notices, email instructions, etc; and/ or
        \n(p) to comply with any applicable rules, laws and regulations, codes of practice or guideline or assisting in law enforcement and investigations by relevant authorities.
        \nWe will not sell your data, including Personal Data or Usage Data, to any third party.
        \nWhere permitted, we may also collect, use and disclose your Personal Data for marketing purposes with your consent. Marketing communications includes, but are not limited to, newsletters or promotional materials, special offers and general information about other goods, services and/ or events which we offer that are similar to those that you have   already purchased or enquired about, and other information that may be of interest to you. You have a choice to withdraw your consent to receive any, or all, of these communications from us by following the unsubscribe link or by emailing us at data@thoth.art. Upon receipt of your withdrawal of consent, please note that it may take up to 30 working days for us to process this request. In the interim, you may still receive marketing communications/materials.
        \nYou should ensure that all Personal Data submitted to us is complete, accurate, true and correct. Failure on your part to do so may result in our inability to provide you with services you have requested, either to the same standard as if you had provided the Personal Data or at all.`
      ]
    },
    {
      title: "Retention of Data",
      texts: [
        `We will retain and use Personal Data and Usage Data as set out in this Privacy Policy. We will retain and use Personal Data and Usage Data to the extent necessary to fulfil the purposes for which it was collected, or for analytical purposes, or to comply with applicable laws, resolve disputes, and/ or enforce rights or obligations. Thereafter, we will cease to retain the Personal Data and/ or Usage Data or remove the means by which such data can be associated with you.`
      ]
    },
    {
      title: "Transfer of Data",
      texts: [
        `Personal Data and/ or Usage Data may be transferred from the country in which you are present ("Home Country") while you are using the Service, to, and maintained  on computers located outside of the Home Country where the applicable laws may differ.
        \nThoth will take such steps as Thoth deems reasonably necessary to ensure Personal Data and/ or Usage Data is treated securely and in accordance with this Privacy Policy and applicable laws.`
      ]
    },
    {
      title: "Disclosure of Data",
      texts: [
        `We may disclose Personal Data and/ or Usage Data in the following situations:
        \n(i) if we deem such disclosure necessary or desirable to comply with applicable laws or with requests by regulators or authorities;
        \n(ii) if we are involved in a corporate transaction including but not limited to mergers or amalgamations, disclosure may be made to potential acquirers and their affiliates and advisers;
        \n(iii) disclosure may be made to third parties who assist us in providing the Service, such as contractors, advisers, and Service Providers;
        \n(iv) our subsidiaries, related companies, Affiliates, or partners; and/ or
        \n(v) disclosure may be made in order to provide the Service or to otherwise fulfill any other purpose agreed by or notified to you.
        \nWe may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used. As at the date of this Privacy Policy, our Service Providers include:`,
        `<br/>`,
        `<ul><li>Google Analytics for analytical purposes</li></ul>`,
        `<ul><li>Stripe for payments; and</li></ul>`,
        `<ul><li>GitHub to automate the development process of our Service.</li></ul>`,
        `We encourage you to review:`,
        `<br/>`,
        `<ul><li>Google's privacy policy: ${this.getStyledLink(
          "https://policies.google.com/privacy?hl=en"
        )}</li></ul>`,
        `<ul><li>Stripe's privacy policy: ${this.getStyledLink(
          "https://stripe.com/us/privacy"
        )}</li></ul>`,
        `<ul><li>GitHub's privacy policy: ${this.getStyledLink(
          "https://help.github.com/en/articles/github-privacy-statement"
        )}</li></ul>`
      ]
    },
    {
      title: "Protection of Data",
      texts: [
        `Thoth cares about the security of your information. We take measures to safeguard Personal Data and Usage Data from unauthorised access, collection, use, disclosure, copying, modification, disposal, or similar risks by employing appropriate safeguards including but not limited to, encryption, two-step verification and password protection. You are responsible for maintaining the secrecy of your unique password and account information.
        \nIn no event shall we or any of our directors, officers, employees, agents or licensors (collectively, the "Protected Entities") be liable to you or any other party for any liability, obligation, damages, claims, penalties, actions, legal fees, disbursements, expenses, and losses (including but not limited to any direct, indirect or consequential losses, economic loss, and loss of reputation) ("Loss") resulting from (a) your use or inability to use the Website; (b) any unauthorised use of your account; (c) any unauthorised use of the Website; and/ or (d) any security defects in the Website and/ or Service which does not directly result from our acts or omissions, or which is beyond our reasonable control.
        \nAlthough Thoth regularly reviews and takes steps to enhance its security measures, Thoth does not and cannot guarantee that no Personal Data or Usage Data will be accessed, disclosed, altered, or destroyed. Further, Thoth cannot be responsible for any actions or omissions of a third party, including but not limited to the security measures used by third party applications, sites or services that interact with the Service.`
      ]
    },
    {
      title: "Links to Other Sites",
      texts: [
        `Our Service may contain links to other sites that are not operated by us ("Hyperlinks"). If you click on a Hyperlink, you will be directed to that third party's site. The linked sites are not subject to Thoth's control and are governed by their own privacy policies. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`
      ]
    },
    {
      title: "Use of Cookies",
      texts: [
        `If you would like to manage, block or delete cookies you should be able to do so through your internet browser. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of the Website. By using the Website without deleting or rejecting some or all cookies, you consent to the collection, use and disclosure of any Personal Data and/ or Usage Data by the cookies that you have chosen to accept and you agree that we can place those cookies that you have not deleted or rejected on your device.
        \nThoth uses cookies to recognise your browser or device, and provide you with essential features and services for additional purposes, including;
        \n(i) recognising you when you sign-in to use the services;
        \n(ii)	conducting diagnostics and statistical analysis to improve the Website;
        \n(iii) measuring and analysing the performance of the Website;
        \n(iv) preventing fraudulent activity; and
        \n(v) improving security.
        \nA cookie is a text file that is placed on your hard drive by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. Third parties may also use cookies, over which we have no control.`
      ]
    },
    {
      title: "Children's Policy",
      texts: [
        `Our Services are not intended for use by children under the age of 13 (“Children”). You represent and warrant that you have the right capacity and legal capacity required to use and access the Website and/ or its related services. If you are a Child, you represent and warrant that you are using the Website and/ or Service with the consent of your guardian. In that regard, we are entitled to rely on such consent or refusal given by your guardian. If we become aware that we have collected Personal Data from Children  without verification of consent from a parent/guardian, we will take steps to remove all Personal Data in relation to the Child from our servers within 30 working days.`
      ]
    },
    {
      title: "Changes to This Privacy Policy",
      texts: [
        `This Privacy Policy applies in conjunction with any other notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your Personal Data by us. In case of any inconsistencies, this Privacy Policy shall prevail.
        \nWe reserve the right, at our sole discretion to change, modify or otherwise alter this  Privacy Policy from time to time to reflect changes in, for example, our services or relevant laws. The "Last updated" legend at the top of this   Privacy Policy indicates when this Privacy Policy was last revised. Your continued use of the Website and/ or Service constitutes your acknowledgement and acceptance of such changes, modifications and alterations.
        \nSuch updates on our Privacy Policy will be made known prior to the updates becoming effective via a prominent notice on our Website, and, if you are a User, via the email address associated with your account. By continuing to access or use the Service and/ or the Website after those changes, alterations and modifications, become effective, you agree to be bound by the revised Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.`
      ]
    },
    {
      title: "Withdrawal of Consent",
      texts: [
        `The consent that you provide for the collection, use and disclosure of your Personal Data will remain valid until such time it is being withdrawn by you in writing.
        \nYou may withdraw consent and request us to stop using and/ or disclosing your Personal Data for any or all of the purposes listed above by submitting your request in writing or via email to our Data Protection Officer via email at ${this.getStyledLink(
          "data@thoth.art",
          true
        )}.
        \nPlease note that if your Personal Data has been provided to us by a third party, you should contact such party directly to make any queries, feedback, and access and correction requests to us on your behalf.
        \nUpon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and obligations. In general, we shall seek to process your request within five (5) working days of receiving it.
        \nWe fully respect your decision to withdraw your consent. However, depending on the nature and scope of your request, we may not be able to continue providing our services to you. In such circumstances we shall notify you before completing the processing of your request.
        \nShould you decide to cancel your withdrawal of consent, please inform us in writing in the manner described above.
        \nPlease note that withdrawal of consent does not affect our right to continue to collect, use and disclose Personal Data where such collection, use and disclose without consent is permitted or required under applicable laws, rules or regulations.
        \nYour Personal Data may be retained for a reasonable period after your relationship with us has been altered in any way or has ended, for reasonable purposes, such as to resolve any concerns between you and us.`
      ]
    },
    {
      title: "Access to and Correction of Personal Data",
      texts: [
        `If you wish to make a request for access to a copy of your Personal Data or information on the ways in which we use or disclose your Personal Data, or a request to correct or update any of your Personal Data, you may submit your request to our Data Protection Officer via email at ${this.getStyledLink(
          "data@thoth.art",
          true
        )}.
        \nWe will respond to your request as soon as reasonably possible, which is typically expected to be no more than 15 working days.`
      ]
    }
  ];

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
  getStyledLink(link: string, email = false) {
    const href = email ? `mailto:${link}` : link;
    return `<a href="${href}" class="link--thoth">${link}</a>`;
  }
  getStyledSubtitle(subtitle: string) {
    return `<span style="font-weight: 600">${subtitle}</span><br />`;
  }
}
