import { createApp } from "vue";
import VueGtag from "vue-gtag";
import VueLazyLoad from "vue3-lazyload";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/index.js";
import { GA_TRACKING_ID } from "@/services";
import { Vue3Mq } from "vue3-mq";
import setupInterceptors from "./services/setupInterceptors";

setupInterceptors(store);

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || "Thoth";
  next();
});

createApp(App)
  .use(router)
  .use(store)
  .use(Vue3Mq, {
    breakpoints: {
      xs: 0,
      sm: 320,
      md: 480,
      lg: 768,
      xl: 960,
      xxl: 1200,
    },
  })
  .use(
    VueGtag,
    {
      config: {
        id: GA_TRACKING_ID,
        appName: "Thoth",
      },
    },
    router
  )
  .use(VueLazyLoad, { log: false })
  .mount("#app");
