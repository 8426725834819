<template>
  <div id="app">
    <NavBar />
    <HeroSection />
    <StagesCollection />
    <SessionHighlights />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import HeroSection from "./components/HeroSection.vue";
import StagesCollection from "./components/StagesCollection.vue";
import SessionHighlights from "./components/SessionHighlights.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    HeroSection,
    StagesCollection,
    SessionHighlights,
    Footer
  }
};
</script>

<style scoped>
@import "./components/webflow.css"; /* You can add component-specific styles here if needed, or use your global CSS file */
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
