<template>
  <div id="app">
    <NavBar />
    <!-- Passing the route param as a prop to Stage.vue -->
    <Talk :talkName="$route.params.name" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import Talk from "./components/Talk.vue";

export default {
  name: "TalkPage",
  components: {
    NavBar,
    Footer,
    Talk
  }
};
</script>

<style scoped>
@import "./components/webflow.css";
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
