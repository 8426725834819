<template>
  <router-view />
</template>

<style lang="scss">
@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/uikit-theme.scss";
@import "./variables.scss";

/* =================
   Table of Contents
   =================

   1. Root Variables
      - Thoth Colours (Egyptian Blue etc.)
      - Measurements
   2. Typography
   3. UIKit overrides (TODO: Review)

   ------------------------------------*/

:root {
  /* =============
     Thoth Colours
     ============= */

  --egyptianblue: #1b5cd3;
  --egyptianblue-75: #659dea;
  --egyptianblue-50: #99bff1;
  --egyptianblue-25: #cbdef8;
  --egyptianblue-10: #e8effb;

  --golden: #f7ce46;
  --golden-75: #f9da74;
  --golden-50: #fbe6a2;
  --golden-25: #fdf3d1;

  --peach: #f17857;
  --peach-75: #ff8c70;
  --peach-50: #ffc5b8;
  --peach-25: #fcddd5;
  --peach-10: #fef2ee;

  --lavender: #deb3e7;
  --lavender-75: #f0d0f3;
  --lavender-50: #f7e0f8;
  --lavender-25: #f7ecf9;

  --ink: #111111;
  --ink-90: #292929;
  --ink-80: #414141;
  --ink-70: #585858;
  --ink-60: #707070;
  --ink-50: #888888;
  --ink-40: #a0a0a0;
  --ink-30: #b8b8b8;
  --ink-20: #cfcfcf;
  --ink-10: #e7e7e7;
  --ink-5: #f3f3f3;

  --paper: #f8f8f8;
  --white: #ffffff;
  --black: #000000;

  /* ============
     Measurements
     ============ */

  --nav-drawer-width: 300px;
  --footer-height: 325px;
  --header-height: 72px;
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  :root {
    --footer-height: 338px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  :root {
    --footer-height: 680px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --footer-height: 665px;
  }
}

/* ================
   Thoth Typography
   ================

   (A) Font Families

   1. Primary: Brick Display
   2. Secondary: Baton Turbo

   (B) Thoth Font Classes

   1. Display
   2. Headings
   3. Body

   -------------------*/

// Primary - Brick Display
@font-face {
  font-family: "Brick";
  src: url($static-asset-font-base-url+"/Brick/brick-text-black.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Brick";
  src: url($static-asset-font-base-url+"/Brick/brick-text-pro-medium.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Brick";
  src: url($static-asset-font-base-url+"/Brick/brick-display-regular.woff2")
    format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Brick";
  src: url($static-asset-font-base-url+"/Brick/brick-display-pro-light.woff2")
    format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Brick";
  src: url($static-asset-font-base-url+"/Brick/brick-text-extralight.woff2")
    format("woff2");
  font-weight: 200;
}

// Secondary: Baton Turbo
@font-face {
  font-family: "Baton Turbo";
  src: url($static-asset-font-base-url+"/BatonTurbo/BatonTurboWeb-Heavy.woff2")
    format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Baton Turbo";
  src: url($static-asset-font-base-url+"/BatonTurbo/BatonTurboWeb-Bold.woff2")
    format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Baton Turbo";
  src: url($static-asset-font-base-url+"/BatonTurbo/BatonTurboWeb-Medium.woff2")
    format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Baton Turbo";
  src: url($static-asset-font-base-url+"/BatonTurbo/BatonTurboWeb-Book.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Baton Turbo";
  src: url($static-asset-font-base-url+"/BatonTurbo/BatonTurboWeb-Regular.woff2")
    format("woff2");
  font-weight: 400;
}

// Display
.display-1 {
  font: normal 400 80px/88px Brick;
  text-transform: capitalize;
}

.display-2 {
  font: normal 400 60px/66px Brick;
  text-transform: capitalize;
}

.display-3 {
  font: normal 400 48px/52.8px Brick;
  text-transform: capitalize;
}

.display-4 {
  font: normal 400 40px/44px Brick;
  text-transform: capitalize;
}

// Headings
.heading-1 {
  font: normal 600 48px/52.8px "Baton Turbo";
  text-transform: capitalize;
}

.heading-2 {
  font: normal 600 40px/44px "Baton Turbo";
  text-transform: capitalize;
}

.heading-3 {
  font: normal 600 32px/35.2px "Baton Turbo";
}

.heading-4 {
  font: normal 600 28px/30.8px "Baton Turbo";
}

.heading-5 {
  font: normal 600 24px/31.2px "Baton Turbo";
}

.heading-6 {
  font: normal 600 20px/26px "Baton Turbo";
}

// Body

.body-1 {
  font: normal 400 18px/23.4px "Baton Turbo";
}
.body-2 {
  font: normal 400 16px/20.8px "Baton Turbo";
}
.body-3 {
  font: normal 400 14px/18.2px "Baton Turbo";
}
.body-4 {
  font: normal 400 12px/15.6px "Baton Turbo";
}

// Link Font

.link--thoth {
  font-weight: 500;
  color: var(--egyptianblue);
}

.link--thoth:hover {
  color: var(--egyptianblue-75);
  text-decoration: none;
}

// UIKit overrides

.uk-offcanvas-bar {
  width: var(--nav-drawer-width);
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: var(--nav-drawer-width);
}

.uk-open > .uk-offcanvas-reveal {
  width: var(--nav-drawer-width);
}

//transition styles
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fastfade-enter-active,
.fastfade-leave-active {
  transition: all 0.25s linear;
}

.fastfade-enter-from,
.fastfade-leave-to {
  opacity: 0;
}

//mobile blocker
@media screen and (max-width: 1269px) {
  .container__mobile__blocker {
    display: none;
  }
}
</style>
