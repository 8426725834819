<template>
  <div class="section footer_section">
    <div class="footer">
      <div class="container">
        <div class="footer_content_wrapper">
          <div class="logo_and_subscribe_wrap">
            <!-- <a
              href="http://www.ideaink.co"
              class="link-block-2 w-inline-block"
            >
              <div class="text-block-18">Brought to you by:</div>
              <img
                :src="require('@/views/WebLibrary/images/Idea-Ink-Logo-WOB.png')"
                width="200"
                sizes="(max-width: 479px) 80vw, 200px"
                :srcset="ideaInkLogoSrcSet"
                alt="Idea Ink Logo"
                class="footer_logo"
              />
            </a> -->
            <!-- <div class="footer_social_wrap">
              <a
                href="https://www.instagram.com/_ideaink/"
                target="_blank"
                class="social_media_link w-inline-block"
              >
                <img
                  :src="require('@/views/WebLibrary/images/Instagram_white.svg')"
                  width="35"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/idea-ink-illustrations/"
                target="_blank"
                class="social_media_link w-inline-block"
              >
                <img
                  :src="require('@/views/WebLibrary/images/LinkedIN_white.svg')"
                  width="35"
                  alt="LinkedIn"
                />
              </a>
              <a
                href="https://www.facebook.com/IdeaInkIllustrations/"
                target="_blank"
                class="social_media_link first w-inline-block"
              >
                <img
                  :src="require('@/views/WebLibrary/images/Facebook_white.svg')"
                  width="35"
                  alt="Facebook"
                />
              </a>
              <a
                href="https://twitter.com/_ideaink"
                target="_blank"
                class="social_media_link w-inline-block"
              >
                <img
                  :src="require('@/views/WebLibrary/images/Twitter_white.svg')"
                  width="35"
                  alt="Twitter"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCs-3uaTzpzdH7PHxZe3ZL5g"
                target="_blank"
                class="social_media_link w-inline-block"
              >
                <img
                  :src="require('@/views/WebLibrary/images/Youtube_white.svg')"
                  width="35"
                  alt="YouTube"
                />
              </a>
            </div> -->
            <!-- <div class="div-block-40"></div> -->
            <a href="http://www.thoth.art" class="link-block-2 w-inline-block">
              <div class="text-block-18">Brought to you by:</div>
              <img :src="require('@/views/WebLibrary/images/thoth-yellow.svg')" loading="lazy" width="216" alt="Thoth"
                class="image-20" />
            </a>
            <div class="footer_social_wrap">
              <a href="https://www.linkedin.com/company/thoth-ai/" target="_blank"
                class="social_media_link w-inline-block">
                <img :src="require('@/views/WebLibrary/images/LinkedIN_white.svg')" width="35" alt="LinkedIn" />
              </a>
              <a href="https://x.com/_thothAI" target="_blank" class="social_media_link w-inline-block">
                <img :src="require('@/views/WebLibrary/images/X_white.svg')" width="35" alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/th0th.ai" target="_blank" class="social_media_link w-inline-block">
                <img :src="require('@/views/WebLibrary/images/Instagram_white.svg')
                  " width="35" alt="Twitter" />
              </a>
              <a href="https://form.typeform.com/to/Tbyclmz4" target="_blank" class="button-3 yellow-outline w-button">
                JOIN WAITLIST
              </a>
            </div>
          </div>
          <div class="footer_links_wrapper">
            <div class="footer_nav_column left-footer">
              <div class="text-block-24">24FINTECH<br />VISUAL NOTES</div>
              <router-link :to="`/24fintech2024/day/1`" class="footer_link">DAY 1</router-link>
              <router-link :to="`/24fintech2024/day/2`" class="footer_link">DAY 2</router-link>
              <router-link :to="`/24fintech2024/day/3`" class="footer_link">DAY 3</router-link>
              <router-link :to="`/24fintech2024/agenda`" class="footer_link">AGENDA</router-link>
            </div>
            <div class="footer_nav_column right-footer">
              <div class="text-block-24">EXPLORE MORE</div>
              <a href="https://24fintech.com/agenda" class="footer_link">24FINTECH WEBSITE</a>
            </div>
            <div class="footer_nav_column right-footer">
              <div class="text-block-24">WEBSITES</div>
              <!-- <a href="https://ideaink.co" class="footer_link">IDEA INK</a> -->
              <a href="https://thoth.art/" class="footer_link">THOTH.AI</a>
            </div>
          </div>
          <!-- <img :src="require('@/views/WebLibrary/images/Thoth-mobile-Homepage-spot-08.png')" loading="lazy" width="320"
            sizes="(max-width: 479px) 100vw, 320px" alt="" :srcset="thothLogoSrcSet" class="image-21" /> -->
        </div>
      </div>
    </div>
    <div class="copyrights_contain">
      <div class="copyright-text">
        <div class="copyrights-header">
          © Copyright 2022
          <a href="https://thoth.art/" target="_blank" class="link-5">Thoth</a>.
          <br />
        </div>
        <div class="copyrights">
          All images and work product are the proprietary products of Thoth and
          fully protected by the Copyright Act (Cap. 63 of 2006) of Singapore
          and other applicable laws. The revocable licence to use Thoth shall
          not result in the transfer of any intellectual property rights to any
          user. By using Thoth, you agree that in consideration of the grant of
          the licence, all rights in any user input shall be irrevocably signed
          to Thoth.<br />
        </div>
      </div>
    </div>
    <div class="footer_svg_wrap">
      <img src="https://uploads-ssl.webflow.com/5fbcc336691f930575282d4c/5fbcc336691f9384c7282dd4_footer%20svg.svg"
        width="304" alt="" class="footer_svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    ideaInkLogoSrcSet() {
      return `
        ${require("@/views/WebLibrary/images/Idea-Ink-Logo-WOB-p-500.png")} 500w,
        ${require("@/views/WebLibrary/images/Idea-Ink-Logo-WOB-p-800.png")} 800w,
        ${require("@/views/WebLibrary/images/Idea-Ink-Logo-WOB-p-1080.png")} 1080w,
        ${require("@/views/WebLibrary/images/Idea-Ink-Logo-WOB-p-1600.png")} 1600w,
        ${require("@/views/WebLibrary/images/Idea-Ink-Logo-WOB.png")} 2320w
      `;
    },
    thothLogoSrcSet() {
      return `
        ${require("@/views/WebLibrary/images/Thoth-mobile-Homepage-spot-08-p-500.png")} 500w,
        ${require("@/views/WebLibrary/images/Thoth-mobile-Homepage-spot-08-p-800.png")} 800w,
        ${require("@/views/WebLibrary/images/Thoth-mobile-Homepage-spot-08-p-1080.png")} 1080w,
        ${require("@/views/WebLibrary/images/Thoth-mobile-Homepage-spot-08.png")} 1500w
      `;
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
