<template>
  <div id="app">
    <NavBar />
    <!-- Content Area where SearchResults.vue is included -->
    <SearchResults />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import SearchResults from "./components/SearchResults.vue";

export default {
  name: "SearchResultsPage",
  components: {
    NavBar,
    Footer,
    SearchResults
  }
};
</script>

<style scoped>
/* Add any page-specific styles here */
</style>
