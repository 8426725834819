<template>
  <div class="div-block-36">
    <div class="div-block-86">
      <div class="div-block-35">
        <h3 class="heading-18">Session Highlights:<br /></h3>
      </div>
      <div class="collection-list-wrapper-5">
        <div v-if="lightboxData.items.length > 0" role="list" class="collection-list">
          <div v-for="(session, index) in lightboxData.items" :key="index" role="listitem" class="collection-item">
            <div class="template-content-2">
              <div class="template-image-2">
                <!-- Optional category display -->
                <!-- <div :style="{ backgroundColor: getCategoryColor(session.category) }" class="top_content">
                  <div class="category-6">{{ session.category }}</div>
                </div> -->
                <div v-if="session.imageUrl" class="div-block-80">
                  <img :src="session.imageUrl" loading="lazy" alt="Session Image" class="image-17 responsive-image" />
                </div>

                <!-- If content.imageUrl does NOT exist, display the placeholder -->
                <div v-else class="div-block-81">
                  <img src="https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/images/Holding+Slide.png"
                    loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image"
                    class="image-39 responsive-image" />
                </div>
              </div>
              <div class="template-title-wrapper">
                <h6 class="body1">{{ session.title }}</h6>
                <div class="rich-text-block-4 w-richtext">
                  <p v-for="(speaker, index) in session.speakers" :key="index">
                    <strong>{{ speaker.name }}</strong> |
                    {{ speaker.designation }}, {{ speaker.company }}<br />
                  </p>
                </div>
              </div>
              <div class="template-content-2 dark no_opacity">
                <router-link :to="generateEndpoint(session.title)" class="template-link w-inline-block"></router-link>
                <div class="template-title-wrapper large-margin">
                  <h6 class="body1 white heading-session-highlights">
                    {{ session.title }}
                  </h6>

                  <div class="rich-text-block-4 pink w-richtext">
                    <div>{{ session.description }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class=" w-dyn-empty">
          <div>No items found.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SessionHighlights",
  data() {
    return {
      content: {}, // Store the content for the current talk
      lightboxData: {
        items: [],
        group: ""
      },
      data: null // Initialize data as null
    };
  },
  methods: {
    getCategoryColor(category) {
      const colors = {
        "EXECUTIVE SUMMIT": "#231f20",
        "FUTURES FORUM": "#95733b",
        "FINTECH FUSION": "#19C8E1",
        "24\u00b0 TRENDS": "#005430"
        // Add more categories and their colors as needed
      };
      return colors[category] || "#000000"; // Default color if category not found
    },
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/fintech24/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        this.loadData();
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    generateEndpoint(title) {
      return `/24fintech2024/talk/${title
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .trim()}`; // Trim any extra whitespace
    },
    loadData() {
      if (!this.data) return; // Ensure data is available before proceeding

      // Find the top 10 talks based on rating
      const top10Talks = this.data
        // .sort((a, b) => b.rating - a.rating) // Sort by rating in descending order
        // .slice(0, 10); // Select the first 10 items
        .filter(talk => talk.updatedAt) // Keep only entries with a non-null 'updatedAt'
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by most recent updates
        .slice(0, 10); // Select the first 10 items

      this.lightboxData.items = top10Talks;
    },
    formatDate(date) {
      // Format the date as "dd MMM"
      const options = { day: "2-digit", month: "short" };
      return new Date(date).toLocaleDateString("en-US", options);
    }
  },
  watch: {
    $route() {
      // Reload data when the route changes
      this.loadData();
    }
  },
  async mounted() {
    // Fetch data when the component is mounted
    await this.fetchData();
  }
};
</script>

<style scoped>
.responsive-image {
  width: 100%;
  /* Fill the width of the container */
  height: 200px;
  /* Set a specific height */
  object-fit: cover;
}
</style>
